import React, { useRef, useState } from 'react';
import logo from "./img/logo.png";
import imagem1 from "./img/idvisual.jpg";
import video from "./img/propaganda.webm";
import capa from "./img/capatudo.png";
import serviço1 from "./img/serviço1.jpg";
import serviço2 from "./img/Business-Intelligence-800x493-1.jpg";
import serviço3 from "./img/analisesecoleta.jpeg";
import serviço4 from "./img/image.jpg";
import bussiness from "./img/COMOFUNCIONA.jpg";
import crefito1 from "./img/crefito1.png"
import bombeiro from "./img/logo_cbmpb_vermelho.png"
import governopb from "./img/governopb.png"
import abrace from "./img/abrace.png"
import telefone from "./img/telephone.png"
import whatsapp from "./img/whatsapp.png"
import email from "./img/gmail.png"
import axios from "axios"
import './main.css';

const dataAtual = new Date()
const currentYear = dataAtual.getFullYear()

function App() {
  const contatoRef = useRef(null);
  const videoRef = useRef(null);
  const servicoRef = useRef(null);
  const biRef = useRef(null);
  const depoimentosRef = useRef(null);
  const [dados, setDados] = useState({})

  const scrollTo = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };
  const update = (event) => {
    const { name, value } = event.target
    const tempDados = { ...dados }
    tempDados[name] = value
    setDados(tempDados)
  }
  const enviar = () => {
    const url = "https://gestaocbmpb.herokuapp.com/api/conversation/siteEmpresa"
    axios.post(url, dados)
      .then(resposta => alert(resposta))
      .catch(error => alert(error)) 
  }


  return (
    <>
      <header className="urls">
        <div className="logo">
          <img src={logo} alt="imagem da logo" />
        </div>
        <nav className="menu">
          <ul>
            <li><a style={{ cursor: "pointer" }}>HOME</a></li>
            <li><a style={{ cursor: "pointer" }} onClick={() => scrollTo(videoRef)}>MB TECH</a></li>
            <li><a style={{ cursor: "pointer" }} onClick={() => scrollTo(servicoRef)}>SERVIÇOS</a></li>
            <li><a style={{ cursor: "pointer" }} onClick={() => scrollTo(biRef)}>BUSINESS INTELLIGENCE</a></li>
            {/* <li><a style={{ cursor: "pointer" }} onClick={() => scrollTo(depoimentosRef)}>DEPOIMENTOS</a></li> */}
            <li><a style={{ cursor: "pointer" }} onClick={() => scrollTo(contatoRef)}>CONTATO</a></li>
          </ul>
        </nav>
        {/* <div className="busca">
          <input placeholder="CONVERSE COM NOSSA INTELIGÊNCIA ARTIFICIAL" type="text" />
        </div> */}
      </header>
      <div className="col-100">
        <div className="slider-container">
          <div className="slider-principal">
            <div className="slide active">
              <img src={imagem1} alt="Slide 1" />
            </div>
          </div>
        </div>
      </div>

      <div className="video-texto" ref={videoRef}>
        <div className="video-container">
          <video controls src={video} type="video/webm" poster={capa}></video>
        </div>
        <div className='texto-container'>
          <p>
            <strong>MB TECH</strong>
            <br /><br />
            A MB Tech é uma empresa especializada em oferecer soluções digitais e
            inovações para empresas de diversos setores. Nossos serviços incluem a
            estruturação de produtos digitais, desenvolvimento de plataformas de coleta
            e análise de dados, além da aplicação de inteligência artificial para proporcionar
            o que há de mais moderno na atualidade. Nosso objetivo é ajudar as empresas a
            alcançarem insights valiosos e aprimorarem a experiência de seus colaboradores
            e clientes finais. Conte conosco para impulsionar a transformação digital do seu negócio.
          </p>
          <div className="button-video">
            <button className="my-button" onClick={() => scrollTo(contatoRef)}>FAÇA SEU ORÇAMENTO AGORA</button>
          </div>
        </div>
      </div>


      <div className="serviços" ref={servicoRef} >
        <div className="imagem-container">
          <img src={serviço1} alt="Imagem 1" />
          <h5> SOFTWARE PERSONALIZADO</h5>
          <p>Desenvolvemos sistemas e aplicativos personalizados,
            atendendo suas necessidades únicas. </p>
        </div>
        <div className="imagem-container">
          <img src={serviço2} alt="Imagem 2" />
          <h5>COLETA DE DADOS</h5>
          <p>  Criamos plataformas especializadas para coletar e analisar os dados da sua empresa.
          </p>
        </div>
        <div className="imagem-container">
          <img src={serviço3} alt="Imagem 3" />
          <h5>ANÁLISE DE DADOS</h5>
          <p>    Analisamos dados de forma abrangente e entregamos insights profissionais.
          </p>
        </div>
        <div className="imagem-container">
          <img src={serviço4} alt="Imagem 4" />
          <h5>ENTREGA DE RELATÓRIOS E KPIs</h5>
          <p>  Exibição de relatórios e indicadores de desempenho (KPIs) em plataforma personalizada.
          </p>
        </div>
      </div>

      <div className="serviçosbi" ref={biRef}>
        <div className="imagem-bi">
          <img src={bussiness} alt="Imagem 2" />
        </div>
        <div className="texto-bi">
          <h4>COMO FUNCIONA O BUSSINESS INTELLIGENCE</h4>
          <p><b>Coleta de dados</b> - Nesta etapa, os dados relevantes são coletados de várias fontes, como bancos de dados, sistemas de gerenciamento, registros de vendas, entre outros. Essa coleta é feita para obter informações sobre o desempenho do negócio.</p>
          <p><b>Análise de dados</b> - Após a coleta, os dados são analisados utilizando técnicas e ferramentas de análise para identificar padrões, tendências, relações e insights relevantes. Isso ajuda a compreender o desempenho atual, identificar áreas de melhoria e oportunidades de crescimento.</p>
          <p><b>Visualização de dados</b> - Nesta etapa, os dados analisados são transformados em visualizações significativas, como gráficos, tabelas e painéis interativos. Isso torna mais fácil entender e interpretar as informações, permitindo uma visualização clara do panorama geral.</p>
          <p><b>Tomada de decisão</b> - Com os dados visualizados e insights obtidos, os tomadores de decisão podem usar essas informações para tomar decisões estratégicas informadas. Isso inclui identificar oportunidades de mercado, otimizar processos, alocar recursos de forma eficiente e responder proativamente a mudanças no ambiente de negócios</p>
        </div>
      </div>
      {/* <div className="card-container" ref={depoimentosRef}>
        <h1 className='depoimentos'>DEPOIMENTOS DOS NOSSOS CLIENTES < img className='logo-img' src={logo} alt='logo' /></h1>
        <div className="card">
          <div className="box">
            <h4>MARIA TEREZA</h4>
            <h5>Cordenadora do Conselho de Saúde</h5>
            <p> A MB Tech nos surpreendeu com sua capacidade de desenvolver soluções personalizadas que atenderam
              às nossas necessidades. Seus sistemas e aplicativos são de alta qualidade e contribuíram significativamente
              para o sucesso de nossos negócios. Recomendamos a MB Tech para qualquer empresa que busca soluções tecnológicas
              sob medida. </p>
          </div>
        </div>
        <div className="card">
          <div className="box">
            <h4>VIVIANE MORAES</h4>
            <h5>Cordenadora do Conselho de Saúde</h5>
            <p>Estamos extremamente satisfeitos com a plataforma de coleta de dados desenvolvida pela MB Tech.
              Ela nos permitiu reunir informações valiosas de forma eficiente e confiável. A equipe da MB Tech
              foi altamente profissional, compreendendo nossas necessidades e entregando uma solução personalizada
              que superou nossas expectativas. Recomendamos seus serviços. </p>
          </div>
        </div>
        <div className="card">
          <div className="box">
            <h4>IARA LUCENA</h4>
            <h5>Cordenadora do Conselho de Saúde</h5>
            <p>A MB Tech foi fundamental para a melhoria de nossos processos de análise de dados.
              Sua abordagem flexível e focada em resultados nos ajudou a extrair insights valiosos de nossas
              informações. A plataforma de visualização de dados fornecida pela MB Tech é intuitiva e de fácil utilização,
              permitindo que nossa equipe tome decisões estratégicas de maneira mais eficiente.</p>
          </div>
        </div>
        <div className="card">
          <div className="box">
            <h4>THAÍS DUARTE</h4>
            <h5>Cordenadora do Conselho de Saúde</h5>
            <p>A parceria com a MB Tech foi um diferencial para nossa empresa.
              Seu expertise em Business Intelligence nos possibilitou analisar dados de forma abrangente
              e obter insights profissionais. Com base nesses insights, tomamos decisões mais embasadas, o
              que contribuiu significativamente para o crescimento de nossos negócios. Recomendamos os serviços
              da MB Tech para quem busca impulsionar seu sucesso por meio da análise de dados.</p>
          </div>
        </div>
      </div> */}

      <div ref={contatoRef} className="contato">
        <div className="header">
          <h1>CONTATO</h1>
        </div>
        <div className="leftBox">
          <div className="form-row">
            <div className="form-column">
              <label htmlFor="nome">Nome</label>
              <input type="text" name="nome" id="name" onChange={(event) => update(event)} />

              <label htmlFor="email">E-mail</label>
              <input type="text" name="email" id="email" onChange={(event) => update(event)} />

              <label htmlFor="tel">Telefone</label>
              <input type="text" name="tel" id="tel" onChange={(event) => update(event)} />

              <label htmlFor="empresa">Empresa</label>
              <input type="text" name="empresa" id="empresa" onChange={(event) => update(event)} />

              <label htmlFor="msg">Mensagem</label>
              <input type="text" name="msg" id="msg" onChange={(event) => update(event)} />
            </div>

            <div className="form-column">
              <label htmlFor="segempresa">Segmento da Empresa</label>
              <input type="text" name="segempresa" id="segempresa" onChange={(event) => update(event)} />

              <label htmlFor="teamanhoempresa">Tamanho da empresa (colaboradores)</label>
              <select name="teamanhoempresa" id="teamanhoempresa" onChange={(event) => update(event)} >
                <option value=""></option>
                <option value="1-10">1-10 Colaboradores</option>
                <option value="11-50">11-20 Colaboradores</option>
                <option value="50-100">51-100 Colaboradores</option>
                <option value="100-1000">100-1000 Colaboradores</option>
              </select>

              <label htmlFor="necessidade">O que você precisa</label>
              <select name="necessidade" id="necessidade" onChange={(event) => update(event)} >
                <option value=""></option>
                <option value="desenvolversiteapp">Desenvolver um novo site ou aplicativo</option>
                <option value="analisedadosprontos">Análise de dados e KPIs de planilhas prontas</option>
                <option value="novosoftwarededados">Novo software de coleta de dados</option>
                <option value="plataformadedados">Plataforma de exibição da análise de dados</option>
                <option value="inteligenciaartificial">Inteligência Artificial</option>
                <option value="outros">Outros</option>
              </select>

              <label htmlFor="site">Website</label>
              <input type="text" name="site" id="site" onChange={(event) => update(event)} />

            </div>

          </div>

          <div className="button-container">
            <button className="btn" onClick={() => enviar()}>ENVIAR</button>
          </div>
        </div>

        <div className="rightBox">
          <div className='icon-text'>
            <img className='contato-icones' src={telefone} alt='fone' />
            <p className='contato-text'>(83) 9 9694-0610</p>
            <a className='link' href="https://wa.me/5583996940610" target="_blank" rel="noopener noreferrer">
              <img className='contato-icones' src={whatsapp} alt='app' />
              <p className='contato-text'>(83)9 9694-0610</p>
            </a>
            <img className='contato-icones' src={email} alt='email' />
            <p className='contato-text'>mbtechbr@outlook.com</p>
          </div>
        </div>
      </div>
      <div className='bloco-clientes'>
        <h2>ALGUNS DOS NOSSOS CLIENTES</h2>
        <div class="col-4">
          <img src={crefito1} alt='conselho' />
        </div>
        <div class="col-4">
          <img src={bombeiro} alt='bombeiro' className="small-image" />
        </div>
        <div class="col-4">
          <img src={abrace} alt='abrace' className='abraceimg' />
        </div>
        <div class="col-4">
          <img src={governopb} alt='governo' className="small-image" />

        </div>
      </div>
      <footer className="footer">
        <div className="container-footer">
          <p>&copy; {currentYear} MBTECH. Todos os direitos reservados.</p>
        </div>
      </footer>
    </>
  );
}

export default App;
